import React from "react";
import '../../Styles/NotAuthorized.css'
const NotAuthorized = () => {
  return  <div className="notauthorized-container">
  <h1 className="error-code">403</h1>
  <p className="error-message">You don't have permission to access this page.</p>
  <a href="/dashboard" className="home-link">Return to Dashboard</a>
</div>;
};
export default NotAuthorized;
