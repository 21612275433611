import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../Styles/AdminNavbar.css";
const AdminNavbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState({
    operators: false,
    api: false,
  });
  const handleMainToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleSubmenuToggle = (submenu) => {
    setSubmenuOpen((prevState) => ({
      ...prevState,
      [submenu]: !prevState[submenu],
    }));
  };

  return (
    <nav className="navbar navbar-expand-md navbar-light bg-light">
      <a className="navbar-brand pb-2" href="/DashBoard">
        DashBoard
      </a>
      <button
        className="navbar-toggler"
        type="button"
        onClick={handleMainToggle}
        aria-expanded={isDropdownOpen}
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className={`collapse navbar-collapse ${isDropdownOpen ? "show" : ""}`}
      >
        <ul className="navbar-nav">
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Master Management
            </a>
            <ul
              style={{ fontFamily: "Poppins" }}
              className="dropdown-menu"
              aria-labelledby="navbarDropdownMenuLink"
            >
              <li className="dropdown-submenu">
                <a className="dropdown-item dropdown-toggle" href="#">
                  Operators
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="/Maintenance">
                      Operators
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/OperatorsUpdate">
                      Operators Update
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/OperatorsParam">
                      Operators Params
                    </a>
                  </li>
                </ul>
              </li>

              <li className="dropdown-submenu">
                <a className="dropdown-item dropdown-toggle" href="#">
                  API
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="/Transactionapi">
                      Transaction API
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/SMSApi">
                      SMS API
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/CommissionCreation">
                      Commission/API Settings
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/APIForwarding">
                      API Forward
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/APIForwardingscheme">
                      API Forward Scheme Wise
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/APICommission">
                      API Commission
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/SMSTemplate">
                      SMS Templates
                    </a>
                  </li>
                </ul>
              </li>

              <li>
                <a className="dropdown-item" href="/Bank">
                  Bank
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/SchemeCreation">
                  Scheme
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/BankIfsc">
                  Bank IFSC Data
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/BBPSServices">
                  BBPS Upload Excel
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/paymentOptimiser">
                  Payment Optimiser
                </a>
              </li>
            </ul>
          </li>

          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="userManagementDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              User Management
            </a>
            <ul
              className="dropdown-menu"
              aria-labelledby="userManagementDropdown"
            >
              <li className="dropdown-submenu">
                <a className="dropdown-item dropdown-toggle" href="#">
                  Customer
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="#">
                      Register
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/MerchantDetails">
                      List View
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      KYC Requests
                    </a>
                  </li>
                </ul>
              </li>

              <li className="dropdown-submenu">
                <a className="dropdown-item dropdown-toggle" href="#">
                  Merchant
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="#">
                      Register
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/MerchantDetails">
                      List View
                    </a>
                  </li>

                  <li>
                    <a className="dropdown-item" href="#">
                      KYC Requests
                    </a>
                  </li>
                </ul>
              </li>

              <li>
                <a className="dropdown-item" href="#">
                  User Logs
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/Invoice">
                  Invoice
                </a>
              </li>
            </ul>
          </li>

          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="reportsDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Reports
            </a>
            <ul className="dropdown-menu" aria-labelledby="reportsDropdown">
              <li>
                <a className="dropdown-item" href="/TransactionReports">
                  Transaction Reports
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/LiveTransactionReport">
                  Live Transaction Reports
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/PendingTransactions">
                  Pending Transactions
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/APISaleReport">
                  API sale report
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/RefundReports">
                  Refund Reports
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/UpdateTransactions">
                  Update Transaction Reports
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/SMSReport">
                  SMS Reports
                </a>
              </li>
              <li className="dropdown-submenu">
                <a className="dropdown-item dropdown-toggle" href="#">
                  Summary
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="/Summary">
                      Summary Report
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/UserSummary">
                      User Summary
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/ReferSummary">
                      Refer Summary
                    </a>
                  </li>
                </ul>
              </li>

              <li>
                <a className="dropdown-item" href="/GSTTDS">
                  GST/TDS
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/UserUsage">
                  User Usage
                </a>
              </li>
              <li className="dropdown-submenu">
                <a className="dropdown-item dropdown-toggle" href="#">
                  Reconciliation
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="/APIReconciliation">
                      API Reconciliation
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/OperatorReconciliation">
                      Operator Reconciliation
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="/OperatorReconciliationcustom"
                    >
                      Operator Reconciliation Custom
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/Extranodebit">
                      Extra/Nodebit
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/MissingBalance">
                      Missing Balance
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="/Transactioncountserwise"
                    >
                      Transaction Count Servicewise
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/Operatorcommwise">
                      Operator Comm userwise
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/Txnprocesscount">
                      Txn.process Count
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/ReconciliationReport">
                      Reconciliation Report
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <li>
                  <a className="dropdown-item" href="/DownlineSummary">
                    Downline Summary
                  </a>
                </li>
                <a className="dropdown-item" href="/PaymentStatus">
                  Payment Status
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/DuplicateTransactions">
                  Duplicate Transactions
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/viewDailyReport">
                  Merchant Reports
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/SettlementTransactions">
                  Settlement Reports
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/RazorpayPayIn">
                  RazorpayPayIn
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/NoPayOutStatus">
                  Missing Fields
                </a>
              </li>

              <li className="dropdown-submenu">
                <a className="dropdown-item dropdown-toggle" href="#">
                  Switch
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="/switch">
                      Schedulers
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/#">
                      APIs
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="userManagementDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Business Management
            </a>
            <ul
              className="dropdown-menu"
              aria-labelledby="userManagementDropdown"
            >
              <li className="dropdown-submenu">
                <a className="dropdown-item" href="#">
                  Banners
                </a>
              </li>

              <li>
                <a className="dropdown-item" href="#">
                  FAQ
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  News Update
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Welcome News Update
                </a>
              </li>
              <li className="dropdown-submenu">
                <a className="dropdown-item dropdown-toggle" href="#">
                  Pages & Media
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="#">
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="">
                      About Us
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Social Media Links
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="appManagementDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              App Management
            </a>
            <ul
              className="dropdown-menu"
              aria-labelledby="appManagementDropdown"
            >
              <li>
                <a className="dropdown-item" href="#">
                  Banners
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Ad Spaces
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Images
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  News Update
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Welcome News Update
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Push Notifications
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  App Versions
                </a>
              </li>
            </ul>
          </li>

          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="appManagementDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Pro Management
            </a>
            <ul
              className="dropdown-menu"
              aria-labelledby="appManagementDropdown"
            >
              <li>
                <a className="dropdown-item" href="/DigitalWallet">
                  Wallet Settings
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Settlement Settings
                </a>
              </li>

              <li>
                <a className="dropdown-item" href="/InstantTransactions">
                  T+1 & T+2 Settings
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/HolidayList">
                  HolidayList
                </a>
              </li>
            </ul>
          </li>

          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="userManagementDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Settings
            </a>
            <ul
              className="dropdown-menu"
              aria-labelledby="userManagementDropdown"
            >
              <li className="dropdown-submenu">
                <a className="dropdown-item" href="#">
                  How Balance alert
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Service Limit Restructions
                </a>
              </li>
              <li className="dropdown-submenu">
                <a className="dropdown-item dropdown-toggle" href="#">
                  Roles
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="/CreateUser">
                      Add
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/EditDetails">
                      View
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  App Versions
                </a>
              </li>
            </ul>
          </li>

          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="appManagementDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Support Center
            </a>
            <ul
              className="dropdown-menu"
              aria-labelledby="appManagementDropdown"
            >
              <li>
                <a className="dropdown-item" href="/BrowseTickets">
                  Browse Tickets
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Raise Complaints
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Payment Requests
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Request Log
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Response Log
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Activity Log
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  App Permission Log
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Duplicate Deposite Log
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Kyc Request Log
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Website Contacts Logs
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  BBPS Complaints Logs
                </a>
              </li>
            </ul>
          </li>
          <a className="navbar-brand pb-2" href="#">
            Search
          </a>
        </ul>
      </div>
    </nav>
  );
};

export default AdminNavbar;
