import navData from "../Components/Constants/navData";

const generateServiceDataWithNavigateTo = (services) => {

  let result = {};

  // Iterate through each field in services
  Object.keys(services).forEach((fieldName) => {
    result[fieldName] = [];

    // Iterate through each main service in the field
    services[fieldName].forEach((service) => {
      const mainService = service.mainService;
      const subservices = service.subservice;

      // Find the corresponding navData entry
      const navField = navData.find((field) => field.fieldName === fieldName);
      if (navField) {
        const navOption = navField.options.find(
          (option) => option.optionName === mainService
        );

        if (navOption) {
          const subOptionData = [];

          // If subOptions exist, add navigateTo for each subOption
          if (navOption.subOptions) {
            subservices.forEach((subservice) => {
              // Check if subservice is not 'null'
              if (subservice !== "null") {
                const navSubOption = navOption.subOptions.find(
                  (option) => option.subOption === subservice
                );
                if (navSubOption) {
                  subOptionData.push({
                    subOption: subservice,
                    navigateTo: navSubOption.navigateTo,
                  });
                }
              }
            });
          } else {
            // If there are no subOptions, just add the navigateTo for the main service
            subOptionData.push({
              subOption: mainService,
              navigateTo: navOption.navigateTo,
            });
          }

          // Push the service data with navigateTo into the result
          result[fieldName].push({
            mainService: mainService,
            subservices: subOptionData,
          });
        }
      }
    });
  });
  return result;
};

export default generateServiceDataWithNavigateTo;
