// store.js
import { configureStore } from "@reduxjs/toolkit";
import merchantReducer from "../features/merchant/merchantSlice";
import serviceReducer from "../features/serviceMaintainanceSlice/serviceMaintainanceSlice";
import transactionsReducer from "../features/transactionReports/transactionReportsSlice";
import loginReducer from "../features/Login/loginSlice";
const store = configureStore({
  reducer: {
    login: loginReducer,
    merchants: merchantReducer,
    serviceMaintanace: serviceReducer,
    transactions: transactionsReducer,
  },
});

export default store;
