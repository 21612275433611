import React from "react";
import "../../Styles/Dashboard2.css";
import { useState } from "react";
import TransactionsGraph from "./TransactionsGraph";
import CardOverview from "./CardOverview";
import ReportCards from "./ReportCards";
// const card = ({title,count}) => {
//   return (
//     <div class="cookieCard">
//       <p class="cookieHeading">{title}</p>
//       <h2 class="cookieDescription"></h2>
//     </div>
//   );
// };
const Dashboard2 = () => {
  const [cardsData, setCardsData] = useState({
    activeMembers: 586,
    reports: 245,
    totalInstallations: 657,
    promembership: 557,
    // merchant: 0,
    // customer: 0
  });

  return (
    <div className="dashboard">
      {/* Top Navbar */}

      {/* Main Content */}
      <div className="content">
        {/* Left Section */}
        <div className="left-section">
          <ReportCards />
          <div className="transactions-container card-shadow">
            <TransactionsGraph />
          </div>
          <div className="transaction-cards">
            <div className="transaction-cards-container">
              <div className="card">
                <div className="card-title">BBPS Transactions</div>
                <div className="card-content">
                  <h1>98k</h1>
                  <div className="status positive">
                    <span>+0.5%</span>
                    <span className="icon">▲</span>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-title">Rental Transactions</div>
                <div className="card-content">
                  <h1>98k</h1>
                  <div className="status positive">
                    <span>+0.5%</span>
                    <span className="icon">▲</span>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-title">Educational Transactions</div>
                <div className="card-content">
                  <h1>98k</h1>
                  <div className="status positive">
                    <span>+0.5%</span>
                    <span className="icon">▲</span>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-title">Wallet Transactions</div>
                <div className="card-content">
                  <h1>98k</h1>
                  <div className="status positive">
                    <span>+0.5%</span>
                    <span className="icon">▲</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="pie-chart-container card-shadow">
              <CardOverview />
            </div>
          </div>
        </div>
        {/* Right Section */}
        <div className="right-section">
          <div className="right-sections-card card-shadow"></div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard2;
