import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { resources } from "../../Components/Resources/Resources";
import axios from "axios";
// Initial State
const initialState = {
  detailsList: [],
  loading: false,
  error: null,
};
// Thunk for Async Save Customer Details
export const saveCustomerDetailsAsync = createAsyncThunk(
  "details/saveCustomerDetails",
  async (detailsList, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        resources.Customer_BaseUrl + `services/saveServiceMaintenanace`,
        detailsList
      );
      console.log(response);
      return { detailsList: response.data.detailsList }; // Adjust to your API response
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Thunk for Async Save Merchant Details
export const saveMerchantDetailsAsync = createAsyncThunk(
  "details/saveMerchantDetails", // Unique prefix
  async (detailsList, { rejectWithValue }) => {
    try {
      console.log('thunk detailsList ====>>>>>>>', detailsList);
      const response = await axios.post(
        resources.Merchant_BaseUrl + `services/saveServiceMaintenanace`,
        detailsList
      );
      console.log(response);
      return response.status
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Slice
const serviceMaintanaceSlice = createSlice({
  name: "serviceMaintanace",
  initialState,
  reducers: {
    addDetail: (state, action) => {
      state.detailsList.push(action.payload);
    },
    updateDetail: (state, action) => {
      const { index, updatedDetail } = action.payload;
      console.log("slice =====>>>>>>", { index, updatedDetail });
      state.detailsList = state.detailsList.map((detail, i) =>
        i === index ? updatedDetail : detail
      );
    },
    updateDetails: (state, action) => {
      console.log("before update=====>>>>>>", state.detailsList );
      console.log("update=====>>>>>>",action.payload);
     state.detailsList = action.payload
    },
    deleteDetail: (state, action) => {
      state.detailsList.splice(action.payload, 1);
    },
    emptyDetails: (state) => {
      state = initialState
    },
  },
  extraReducers: (builder) => {
    builder
      // Save Customer Details
      .addCase(saveCustomerDetailsAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveCustomerDetailsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.detailsList = action.payload.detailsList || [];
      })
      .addCase(saveCustomerDetailsAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Save Merchant Details
      .addCase(saveMerchantDetailsAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveMerchantDetailsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.detailsList = action.payload.detailsList || [];
      })
      .addCase(saveMerchantDetailsAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Actions
export const { addDetail, updateDetail,updateDetails, deleteDetail } = serviceMaintanaceSlice.actions;

// Reducer
export default serviceMaintanaceSlice.reducer;
