import { defaultAllowedRoutes } from "../Routes/Routes";

const flattenServices = (services) => {
  if (!services || typeof services !== "object") {
    console.warn("Invalid services data provided:", services);
    return [];
  }
  let paths = [];
  Object.values(services).forEach((field) => {
    if (Array.isArray(field)) {
      field.forEach((service) => {
        service?.subservices?.forEach((subservice) => {
          if (subservice?.navigateTo && subservice.navigateTo !== "#") {
            paths.push(subservice.navigateTo);
          }
        });
      });
    }
  });
  paths.push(...defaultAllowedRoutes);
  return paths;
};
export default flattenServices;
