const navData = [
  {
    fieldName: "Master Management",
    options: [
      {
        optionName: "Operators",
        subOptions: [
          { subOption: "Operators", navigateTo: "/Maintenance" },
          { subOption: "Operators update", navigateTo: "/OperatorsUpdate" },
          { subOption: "Operators param", navigateTo: "/OperatorsParam" },
        ],
      },
      {
        optionName: "APIs",
        subOptions: [
          { subOption: "Transaction api", navigateTo: "/Transactionapi" },
          { subOption: "SMS Api", navigateTo: "/SMSApi" },
          {
            subOption: "Commission/Api settings",
            navigateTo: "/CommissionCreation",
          },
          {
            subOption: "Api forward scheme wise",
            navigateTo: "/APIForwardingscheme",
          },
          { subOption: "Api commission", navigateTo: "/APICommission" },
          { subOption: "SMS Templates", navigateTo: "/SMSTemplate" },
        ],
      },
      {
        optionName: "Bank",
        navigateTo: "/Bank",
      },
      {
        optionName: "Scheme",
        navigateTo: "/SchemeCreation",
      },
      {
        optionName: "Bank Ifsc data",
        navigateTo: "/BankIfsc",
      },
    ],
  },

  {
    fieldName: "User Management",

    options: [
      {
        optionName: "Merchant",
        subOptions: [
          { subOption: "Register", navigateTo: "#" },
          { subOption: "List/View", navigateTo: "/MerchantDetails" },
          { subOption: "Kyc requests", navigateTo: "/#" },
          { subOption: "View Reports", navigateTo: "/viewDailyReport" },
        ],
      },
      {
        optionName: "Customer",
        subOptions: [
          { subOption: "Register", navigateTo: "#" },
          { subOption: "List/View", navigateTo: "/MerchantDetails" },
          { subOption: "Kyc requests", navigateTo: "#" },
        ],
      },
      {
        optionName: "Users log",
        navigateTo: "#",
      },
    ],
  },
  {
    fieldName: "Reports",

    options: [
      {
        optionName: "Transaction Reports",
        navigateTo: "/TransactionReports",
      },
      {
        optionName: "Live Transaction Reports",
        navigateTo: "/LiveTransactionReport",
      },
      {
        optionName: "Pendings",
        navigateTo: "/PendingTransactions",
      },
      {
        optionName: "Api sale report",
        navigateTo: "/APISaleReport",
      },
      {
        optionName: "Refund Report",
        navigateTo: "/RefundReports",
      },
      {
        optionName: "Update Transaction Reports",
        navigateTo: "/UpdateTransactions",
      },
      {
        optionName: "SMS Reports",
        navigateTo: "/SMSReport",
      },
      {
        optionName: "Summary",
        subOptions: ["Summary", "User Summary", "Refer Summary"],
      },
      {
        optionName: "GST/TDS",
        navigateTo: "/GSTTDS",
      },
      {
        optionName: "User Usage",
        navigateTo: "/UserUsage",
      },
      {
        optionName: "Reconciliation",
        subOptions: [
          {
            subOption: "API Reconciliation",
            navigateTo: "/APIReconciliation",
          },
          {
            subOption: "Operator Reconciliation",
            navigateTo: "/OperatorReconciliation",
          },
          {
            subOption: "Operator Reconciliation Custom",
            navigateTo: "/OperatorReconciliationcustom",
          },
          { subOption: "Extra/Nodebit", navigateTo: "/Extranodebit" },
          { subOption: "Missing Balance", navigateTo: "/MissingBalance" },
          {
            subOption: "Transaction Count Servicewise",
            navigateTo: "/Operatorcommwise",
          },
          {
            subOption: "Operator Comm userwise",
            navigateTo: "/Operatorcommwise",
          },
          { subOption: "Txn.process Count", navigateTo: "/Txnprocesscount" },
          {
            subOption: "Reconciliation Report",
            navigateTo: "/ReconciliationReport",
          },
        ],
      },

      {
        optionName: "Downline Summery",
        navigateTo: "/DownlineSummary",
      },
      {
        optionName: "Payment Status",
      },
      {
        optionName: "Settlement Transactions",
        navigateTo: "/SettlementTransactions",
      },

      {
        optionName: "Duplicate Transactions ",
        navigateTo: "/DuplicateTransactions",
      },
      {
        optionName: "Merchant Reports ",
        navigateTo: "/viewDailyReport",
      },
      {
        optionName: "RazorpayPayIn ",
        navigateTo: "/RazorpayPayIn",
      },
      {
        optionName: "Switch ",
        subOptions: [
          { subOption: "Schedulers", navigateTo: "/switch" },
          { subOption: "APIs", navigateTo: "/#" },
        ],
      },
    ],
  },
  {
    fieldName: "Business Management",

    options: [
      {
        optionName: "Banners",
        navigateTo: "#",
      },
      {
        optionName: "FAQ",
        navigateTo: "#",
      },
      {
        optionName: "News Update",
        navigateTo: "#",
      },
      {
        optionName: "Welcome news Update",
        navigateTo: "#",
      },
      {
        optionName: "Pages&Media",
        subOptions: [
          { subOption: "Privacy Policy", navigateTo: "#" },
          { subOption: "About Us", navigateTo: "/MerchantDetails" },
          { subOption: "Social Media Links", navigateTo: "#" },
        ],
      },
    ],
  },
  {
    fieldName: "App Management",

    options: [
      {
        optionName: "Banner",
        navigateTo: "#",
      },
      {
        optionName: "Ad Spaces",
        navigateTo: "#",
      },
      {
        optionName: "Images",
        navigateTo: "#",
      },
      {
        optionName: "News Update",
        navigateTo: "#",
      },
      {
        optionName: "Welcome News Update",
        navigateTo: "#",
      },
      {
        optionName: "Push  Notification",
        navigateTo: "#",
      },
    ],
  },
  {
    fieldName: "PRO Management",

    options: [
      {
        optionName: "Wallet settings",
        navigateTo: "/DigitalWallet",
      },
      {
        optionName: "Settlement settings",
        navigateTo: "/",
      },
      {
        optionName: "T+1 & T+2",
        navigateTo: "/InstantTransactions",
      },
    ],
  },

  {
    fieldName: "Support Center",

    options: [
      {
        optionName: "browse Tickets",
        navigateTo: "/BrowseTickets",
      },
      {
        optionName: "Raise Complaints",
        navigateTo: "#",
      },
      {
        optionName: "payment Requests",
        navigateTo: "#",
      },
      {
        optionName: "Payment History",
        navigateTo: "#",
      },
      {
        optionName: "Request Log",
        navigateTo: "/RequestLog",
      },
      {
        optionName: "Response Log",
        navigateTo: "/ResponseLog",
      },
      {
        optionName: "Activity Log",
        navigateTo: "/ActivityLog",
      },
      {
        optionName: "App Permission Log",
        navigateTo: "#",
      },
      {
        optionName: "Duplicate Deposite Log",
        navigateTo: "/DuplicateLog",
      },
      {
        optionName: "Kyc   Request Log",
        navigateTo: "#",
      },
      {
        optionName: "Website contacts Logs",
        navigateTo: "#",
      },
      {
        optionName: "BBPS Complaints Logs",
        navigateTo: "#",
      },
    ],
  },

  // Add remaining fields here
];

export default navData;
