import { Suspense, useEffect, useState, lazy, useContext } from "react";
import "./App.css";
import React from "react";
import NavBar from "./Components/NavBar/NavBar";
import {
  useLocation,
  Routes,
  Route,
  useNavigate,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RoutesConfig from "./Routes/Routes"; // Importing RoutesConfig
import { GlobalContext } from "./Components/GlobalContext/GlobalContext";
import generateServiceDataWithNavigateTo from "./helpers/generateNavData";
import PageNotFound from "./Pages/pageNotFound/PageNotFound";
import mapServicesToOptions from "./helpers/mapServicesToOptions";
import flattenServices from "./helpers/flattenServices";
import { useSelector } from "react-redux";
import Dashboard2 from "./Components/Dashboard2/Dashboard2";

import NotAuthorized from './Components/notAuthorized/NotAuthorized'
const Login = lazy(() => import("./Components/Login/Login"));

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const hideNavBar = location.pathname === "/";
  const [allowedServices, setAllowedServices] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const { setDynamicNavData } = useContext(GlobalContext);
  const { token, user } = useSelector((state) => state.login);

  useEffect(() => {
    const fetchData = async () => {
      if (token && user) {
        if (user?.role !== "admin") {
          const mappedServices = mapServicesToOptions(user?.services || []);
          const services = generateServiceDataWithNavigateTo(mappedServices || {});
          setDynamicNavData(services);
          setAllowedServices(flattenServices(services));
        }
      } else {
        setAllowedServices([]);
        if (location.pathname !== "/") {
          navigate("/", { replace: true });
        }
      }
      setLoading(false); // Set loading to false after processing
    };

    fetchData();
  }, [token, navigate, setDynamicNavData, user, location.pathname]);

  const isAllowedRoute = (path) => {
    if (!token || !user) return false; // Prevent checks if logged out
    if (user?.role === "admin") {
      return true;
    }

    const normalizedPath = path?.trim().toLowerCase();
    return allowedServices.some(
      (allowedPath) => allowedPath.toLowerCase() === normalizedPath
    );
  };

  const renderRoute = (route, index) => {
    const isRouteAllowed = isAllowedRoute(route.path);
    if (token) {
      if (isRouteAllowed) {
        return (
          <Route
            key={index}
            path={route.path}
            element={route.element} // Allow access to the route
          />
        );
      } else {
        return (
          <Route
            key={index}
            path={route.path}
            element={<NotAuthorized />} // Redirect if not allowed
          />
        );
      }
    } else {
      return (
        <Route
          key={index}
          path={route.path}
          element={<Navigate to="/" />} // Redirect to login if no token
        />
      );
    }
  };

  return (
    <div className="appDiv">
      {!hideNavBar && token && <NavBar />}
      {loading ? ( // Conditional rendering for loading state
        <div>Loading...</div>
      ) : (
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Login />} />
            {RoutesConfig.map((route, index) => renderRoute(route, index))}
            <Route path="*" element={<PageNotFound />} />
            <Route path="/dashboard2" element={<Dashboard2 />} />
          </Routes>
        </Suspense>
      )}
      <ToastContainer />
    </div>
  );
}

export default App;
