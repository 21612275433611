export const resources = {
  // // Customer_BaseUrl: "https://interface.ntar.com/",
  // Customer_BaseUrl: "http://192.168.0.91:7000/",
  // Merchant_BaseUrl:"http://192.168.171.88:7005/",
  //Merchant_BaseUrl: "http://192.168.0.235:7005/",
  // Merchant_BaseUrl: "https://uat.merchant.ntar.biz/",
  // Merchant_BaseUrl:"https://nodeapp.ntar.biz/",
  // Merchant_BaseUrl: "https://mernode.ntar.biz/",
  Merchant_BaseUrl: "https://interapi.ntar.com/",
  //Customer_BaseUrl: "https://uat.merchant.ntar.biz/",
};

