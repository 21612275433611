import React, { useState, useEffect ,useContext} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../Styles/UpperNavbar.css";
import navData from "../Constants/navData";
import generateServiceDataWithNavigateTo from "../../helpers/generateNavData";
// import mapServicesToOptions from "../helpers/mapServicesToOptions";
import mapServicesToOptions from "../../helpers/mapServicesToOptions";
import { GlobalContext } from "../GlobalContext/GlobalContext"
const UpperNavbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [services, setServices] = useState({});
  const { dynamicNavData,setDynamicNavData } = useContext(GlobalContext);
  // console.log("dynamicNavData====>>>",dynamicNavData);
  const handleMainToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // useEffect(() => {
  //   const userStr = localStorage.getItem("user");
  //   const parsedUser = userStr ? JSON.parse(userStr) : null;
  //   const mppedServices = mapServicesToOptions(parsedUser?.services || []);
  //   const servicesWithNavLinks = generateServiceDataWithNavigateTo(
  //     mppedServices || {}
  //   );

  //   setServices(servicesWithNavLinks);
  // }, []);

  // const result = generateServiceDataWithNavigateTo(services);
console.log("dynamicNavData====>>>>>",dynamicNavData)
  const renderSubservices = (subservices) => {
    return subservices.map((sub, index) => {
      const path = sub.navigateTo || "#"; // Default to '#' if no path is found
      return (
        <li key={index}>
          <a className="dropdown-item" href={path}>
            {sub.subOption} {/* Render the subOption text */}
          </a>
        </li>
      );
    });
  };

  const renderMainServices = (services, category) => {
    const categoryData = navData.find((cat) => cat.fieldName === category);
    return services.map((service, index) => {
      if (
        service?.subservices &&
        service?.subservices[0]?.subOption !== "null"
      ) {
        return (
          <li key={index} className="dropdown-submenu">
            <a className="dropdown-item dropdown-toggle" href="#">
              {service.mainService}
            </a>
            <ul className="dropdown-menu">
              {renderSubservices(service.subservices)}
            </ul>
          </li>
        );
      }

      const path =
        categoryData?.options?.find(
          (option) => option.optionName === service.mainService
        )?.navigateTo || "#";

      return (
        <li key={index}>
          <a className="dropdown-item" href={path}>
            {service.mainService}
          </a>
        </li>
      );
    });
  };

  return (
    <nav className="navbar navbar-expand-md navbar-light bg-light">
      <a className="navbar-brand pb-2" href="/Dashboard">
        Dashboard
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded={isDropdownOpen}
        aria-label="Toggle navigation"
        onClick={handleMainToggle}
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className={`collapse navbar-collapse ${isDropdownOpen ? "show" : ""}`}
      >
        <ul className="navbar-nav">
          {Object.keys(dynamicNavData).map((category, index) => (
            <li key={index} className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id={`${category}Dropdown`}
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded={isDropdownOpen ? "true" : "false"}
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                {category}
              </a>
              <ul
                className="dropdown-menu"
                aria-labelledby={`${category}Dropdown`}
              >
                {renderMainServices(dynamicNavData[category], category)}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default UpperNavbar;
