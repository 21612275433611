import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
} from "recharts";

const data = [
  { name: "BBPS", value: 20, color: "#427fed" },
  { name: "Rental", value: 40, color: "#5ee24b" },
  { name: "Educational", value: 15, color: "#ffa843" },
  { name: "Wallet", value: 15, color: "#d4d4d4" },
];

const CardOverview = () => {
  return (
    <div className="overview-card">
        <h3>Transactions Overview</h3>
      <div className="overview-content">
        <ul className="legend">
          {data.map((entry, index) => (
            <li key={index}>
              <span
                className="dot"
                style={{ backgroundColor: entry.color }}
              ></span>
              <span className="name">{entry.name}</span>
              <span className="value">{entry.value}%</span>
            </li>
          ))}
        </ul>

      <div className="chart-container">
        <ResponsiveContainer width="100%" height={200}>
          <PieChart>
            <Pie
              data={data}
              dataKey="value"
              innerRadius={50}
              outerRadius={70}
              paddingAngle={3}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
      </div>
    </div>
  );
};

export default CardOverview;
