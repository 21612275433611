import React, { createContext, useState } from "react";
export const GlobalContext = createContext();
const GlobalContextProvider = ({ children }) => {
  const [globalUser, setGlobalUser] = useState({});
  const [editableUser, setEditableUser] = useState();
  const [userDetails ,setUserDetails ] =useState({})
  const [dynamicNavData ,setDynamicNavData ] = useState({})
  return (
    <GlobalContext.Provider value={{ globalUser, setGlobalUser ,editableUser,setEditableUser,dynamicNavData,setDynamicNavData}}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
