import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import {
  AreaChart,
  Area,
} from "recharts";

const TransactionsGraph = () => {
  const sampleData = [
    { date: "2024-10-01", count: 5 },
    { date: "2024-10-02", count: 8 },
    { date: "2024-10-03", count: 12 },
    { date: "2024-10-04", count: 20 },
    { date: "2024-10-05", count: 15 },
    { date: "2024-10-06", count: 18 },
    { date: "2024-10-07", count: 25 },
    { date: "2024-10-08", count: 22 },
    { date: "2024-10-09", count: 30 },
    { date: "2024-10-10", count: 28 },
    { date: "2024-10-11", count: 35 },
    { date: "2024-10-12", count: 40 },
    { date: "2024-10-13", count: 38 },
    { date: "2024-10-14", count: 50 },
    { date: "2024-10-15", count: 45 },
    { date: "2024-10-16", count: 60 },
    { date: "2024-10-17", count: 70 },
    { date: "2024-10-18", count: 65 },
    { date: "2024-10-19", count: 80 },
    { date: "2024-10-20", count: 90 },
    { date: "2024-10-21", count: 100 },
    { date: "2024-10-22", count: 110 },
    { date: "2024-10-23", count: 120 },
    { date: "2024-10-24", count: 125 },
    { date: "2024-10-25", count: 130 },
    { date: "2024-10-26", count: 140 },
    { date: "2024-10-27", count: 150 },
    { date: "2024-10-28", count: 160 },
    { date: "2024-10-29", count: 165 },
    { date: "2024-10-30", count: 170 },
    { date: "2024-10-31", count: 180 },
    { date: "2024-11-01", count: 190 },
    { date: "2024-11-02", count: 200 },
    { date: "2024-11-03", count: 210 },
    { date: "2024-11-04", count: 220 },
    { date: "2024-11-05", count: 230 },
  ];

  const data = [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  return (
    <div style={{ width: "100%", height: 400 }}>
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="uv"
            stroke="#9c81ff"
            activeDot={{ r: 8 }}
            dot={false}
            strokeWidth={2.5}
          />
          <Line
            type="monotone"
            dataKey="pv"
            stroke="#ffa3be"
            activeDot={{ r: 8 }}
            dot={false}
            strokeWidth={2.5}
          />
          <Line
            type="monotone"
            dataKey="amt"
            stroke="#55ccff"
            activeDot={{ r: 8 }}
            dot={false}
            strokeWidth={2.5}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TransactionsGraph;
