const optionsData = [
  {
    fieldName: "Master Management",
    options: [
      {
        optionName: "Operators",
        subOptions: ["Operators", "Operators update", "Operators param"],
      },
      {
        optionName: "APIs",
        subOptions: [
          "Transaction api",
          "SMS Api",
          "Commission/Api settings",
          "Api forward",
          "Api forward scheme wise",
          "Api commission",
          "SMS Templates",
        ],
      },
      {
        optionName: "Bank",
        navigateTo: "/Bank",
      },
      {
        optionName: "Scheme",
        navigateTo: "/SchemeCreation",
      },
      {
        optionName: "Bank Ifsc data",
        navigateTo: "/BankIfsc",
      },
    ],
  },

  {
    fieldName: "User Management",

    options: [
      {
        optionName: "Merchant",
        subOptions: ["Register", "List/View", "Kyc requests"],
      },
      {
        optionName: "Customer",
        subOptions: ["Register", "List/View", "Kyc requests"],
      },
      {
        optionName: "Users log",
      },
    ],
  },
  {
    fieldName: "Reports",

    options: [
      {
        optionName: "Transaction Reports",
        navigateTo: "/BbpsReports",
      },
      {
        optionName: "Live Transaction Reports",
        navigateTo: "/LiveTransactionReport",
      },
      {
        optionName: "Pendings",
        navigateTo: "/PendingTransactions",
      },
      {
        optionName: "Api sale report",
        navigateTo: "/APISaleReport",
      },
      {
        optionName: "Refund Report",
        navigateTo: "/RefundReports",
      },
      {
        optionName: "Update Transaction Reports",
        navigateTo: "/UpdateTransactions",
      },
      {
        optionName: "SMS Reports",
        navigateTo: "/SMSReport",
      },
      {
        optionName: "Summary",
        subOptions: ["Summary", "User Summary", "Refer Summary"],
      },
      {
        optionName: "GST/TDS",
        navigateTo: "/GSTTDS",
      },
      {
        optionName: "User Usage",
        navigateTo: "/UserUsage",
      },
      {
        optionName: "Reconciliation",
        subOptions: [
          "API Reconciliation ",
          "Operator Reconciliation",
          "Operator Reconciliation Custom",
          "Extra/Nodebit",
          "Missing Balance",
          "Transaction Count Servicewise",
          "Operator Comm userwise",
          "Txn.process Count",
          "Reconciliation Report",
        ],
      },
      {
        optionName: "Downline Summery",
        navigateTo: "/DownlineSummary",
      },
      {
        optionName: "Payment Status",
      },
      {
        optionName: "Duplicate Transactions ",
        navigateTo: "/DuplicateTransactions",
      },
      {
        optionName: "Merchant Reports ",
        navigateTo: "/viewDailyReport",
      },
      {
        optionName: "Settlement Transactions",
        navigateTo: "/SettlementTransactions",
      },
      {
        optionName: "RazorpayPayIn ",
        navigateTo: "/RazorpayPayIn",
      },
      {
        optionName: "Switch ",
        subOptions: ["Schedulers", "APIs"],
      },
    ],
  },
  {
    fieldName: "Business Management",

    options: [
      {
        optionName: "Banners",
      },
      {
        optionName: "FAQ",
      },
      {
        optionName: "News Update",
      },
      {
        optionName: "Welcome news Update",
      },
      {
        optionName: "Pages&Media",
        subOptions: ["Privacy Policy", "About Us", "Social Media Links", ""],
      },
    ],
  },
  {
    fieldName: "App Management",

    options: [
      {
        optionName: "Banner",
      },
      {
        optionName: "Ad Spaces",
      },
      {
        optionName: "Images",
      },
      {
        optionName: "News Update",
      },
      {
        optionName: "Welcome News Update",
      },
      {
        optionName: "Push  Notification",
      },
    ],
  },
  {
    fieldName: "PRO Management",

    options: [
      {
        optionName: "Wallet settings",
        navigateTo: "/DigitalWallet",
      },
      {
        optionName: "Settlement settings",
        navigateTo: "#",
      },

      {
        optionName: "T+1 & T+2",
        navigateTo: "/InstantTransactions",
      },
    ],
  },

  {
    fieldName: "Support Center",

    options: [
      {
        optionName: "browse Tickets",
      },
      {
        optionName: "Raise Complaints",
      },
      {
        optionName: "payment Requests",
      },
      {
        optionName: "Payment History",
      },
      {
        optionName: "Request Log",
        navigateTo: "/RequestLog",
      },
      {
        optionName: "Response Log",
        navigateTo: "/ResponseLog",
      },
      {
        optionName: "Activity Log",
        navigateTo: "/ActivityLog",
      },
      {
        optionName: "App Permission Log",
      },
      {
        optionName: "Duplicate Deposite Log",
        navigateTo: "/DuplicateLog",
      },
      {
        optionName: "Kyc   Request Log",
      },
      {
        optionName: "Website contacts Logs",
      },
      {
        optionName: "BBPS Complaints Logs",
      },
    ],
  },

  // Add remaining fields here
];

export default optionsData;
