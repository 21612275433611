import "../../Styles/NavBar.css";
import { useNavigate } from "react-router-dom";
import logoImage from "../../assets/logoyellow.png";
import React, { useEffect, useState } from "react";
import UpperNavbar from "../../Components/UpperNavbar/UpperNavbar";
import AdminNavbar from "../AdminNavbar/AdminNavbar";
import { logOut } from "../../features/Login/loginSlice";
import { useDispatch, useSelector } from "react-redux";


const NavBar = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.login);
  const dispatch = useDispatch();

  const handleLogOut = () => {
    console.log("Clearing session storage...");
    localStorage.clear();
    console.log("Dispatching logOut...");
    dispatch(logOut());
    console.log("Navigating to login...");
    navigate("/", { replace: true });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div className="navbar-container">
        <div className="top-bar">
          <div className="logo-container">
            <img src={logoImage} alt="Icon" className="logoIcon" />
            <span className="logo-text">NTAR</span>
          </div>
          <div></div>
          <div className="logout-button-container">
            {/* <span className="user-name">{user?.name}</span>
            <button onClick={handleLogOut} className="logout-button">
              Logout
            </button> */}
             <span className="user-name">{user?.name}</span>
            <button onClick={handleLogOut} class="Btn">
              <div class="sign">
                <svg viewBox="0 0 512 512">
                  <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
                </svg>
              </div>

              <div class="text">Logout</div>
            </button>
          </div>
        </div>
        {user?.role === "admin" ? <AdminNavbar /> : <UpperNavbar />}
      </div>
    </div>
  );
};
export default NavBar;
