import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { resources } from "../../Components/Resources/Resources";

const initialState = {
  mobileNumber: "",
  otp: "",
  isCredentialsSubmitted: false,
  isLoading: false,
  error: null,
  userStatus: null,
  stage: null,
  message: "",
  token: sessionStorage.getItem("token") || "",
  user: JSON.parse(localStorage.getItem("user")) || null,
};

export const submitCredentials = createAsyncThunk(
  "login/submitCredentials",
  async (user, { getState, rejectWithValue }) => {
    try {
      const token = getState().login.token;
      const loaclUser = getState().login.user;

      let response;

      if (!token && loaclUser) {
        // Call signupWithoutOTP if token is missing
        response = await axios.post(
          `${resources.Merchant_BaseUrl}Admindetails/signupWithoutOTP`,
          user
        );

        localStorage.setItem("user", JSON.stringify(response?.data?.user));
        sessionStorage.setItem("token", response?.data?.token);
      } else {
        // Call signup if token exists
        console.log("Admindetails/signup==>>>", user);
        response = await axios.post(
          `${resources.Merchant_BaseUrl}Admindetails/signup`,
          user,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        // Save the token from the response
        sessionStorage.setItem("token", response?.data?.token);
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const submitOtp = createAsyncThunk(
  "login/submitOtp",
  async (otp, { getState, rejectWithValue }) => {
    try {
      const token = getState().login.token;
      console.log("Token in submitOtp:", token);
      console.log("OTP submitted:", otp);

      if (!token) {
        throw new Error("Token is missing or invalid.");
      }

      const response = await axios.post(
        `${resources.Merchant_BaseUrl}Admindetails/verifyOTP`,
        { submittedOTP: otp },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      console.log("Response from OTP verification:", response);
      sessionStorage.setItem("token", response?.data?.token);
      localStorage.setItem("user", JSON.stringify(response.data.roleAdmin));

      return response.data;
    } catch (error) {
      console.error(
        "Error during OTP verification:",
        error.response ? error.response.data : error.message
      );
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setMobileNumber: (state, action) => {
      state.mobileNumber = action.payload;
    },
    setOtp: (state, action) => {
      state.otp = action.payload;
    },
    setIsMobileSubmitted: (state, action) => {
      state.isMobileSubmitted = action.payload;
    },
    setUserStatus: (state, action) => {
      state.userStatus = action.payload;
    },
    setStage: (state, action) => {
      state.stage = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    logOut: (state) => {
      console.log("Before logout:", JSON.stringify(state, null, 2));
      localStorage.clear();
      sessionStorage.clear();
      return {
        ...initialState,
        token: "",
        user: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitCredentials.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(submitCredentials.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.isCredentialsSubmitted = true;
        state.message = action.payload.message;

        if (!state.token && state.user) {
          state.token = action.payload.token;
          state.user = action.payload.user;
        } else {
          state.token = action.payload.token;
          state.isCredentialsSubmitted = true;
          state.message = action.payload.message;
          state.token = action.payload.token;
        }
      })

      .addCase(submitCredentials.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(submitOtp.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(submitOtp.fulfilled, (state, action) => {
        console.log("OTP Verification Response:", action.payload);
        state.isLoading = false;
        state.user = action.payload.roleAdmin;
      })
      .addCase(submitOtp.rejected, (state, action) => {
        console.log("OTP Verification Error:", action.payload);
        state.isLoading = false;
        state.error = action.payload;
        state.userStatus = null;
        state.stage = null;
      });
  },
});

export const {
  setMobileNumber,
  setOtp,
  setIsMobileSubmitted,
  setUserStatus,
  setStage,
  setToken,
  setMerchantId,
  logOut,
} = loginSlice.actions;

export default loginSlice.reducer;
