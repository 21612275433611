import optionsData from "../Components/Constants/optionData";

function mapServicesToOptions(services) {
  if (!Array.isArray(services) || !Array.isArray(optionsData)) {
    console.error("Invalid input: `services` or `optionsData` is not an array.");
    return {};
  }

  return optionsData.reduce((result, { fieldName, options }) => {
    // Ensure options exist and are an array
    if (!fieldName || !Array.isArray(options)) {
      console.warn(`Skipping section: ${fieldName || "Unnamed"} due to invalid options.`);
      return result; // Return the accumulated result
    }

    const mappedServices = services
      .filter(service => service?.mainServices && options.some(option => option.optionName === service.mainServices))
      .map(service => {
        const matchedOption = options.find(option => option.optionName === service.mainServices);
        const filteredSubservices = Array.isArray(service.subServices) && matchedOption?.subOptions
          ? service.subServices.filter(sub => matchedOption.subOptions.includes(sub))
          : service.subServices || []; // Default to original subServices if no matches

        return {
          mainService: service.mainServices,
          subservice: filteredSubservices, // Avoid undefined subservice
        };
      });

    // Add to result only if mappedServices has valid entries
    if (mappedServices.length > 0) {
      result[fieldName] = mappedServices;
    }

    return result; // Return the accumulated result
  }, {});
}

export default mapServicesToOptions;