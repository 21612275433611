import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { resources } from "../../Components/Resources/Resources";
import { toast } from "react-toastify";
// Async Thunks
export const fetchMerchantDetails = createAsyncThunk(
  "merchants/fetchMerchantDetails",
  async (_, thunkAPI) => {
    const loadingToastId = toast.loading("Fetching Merchant details...");

    try {
      const response = await axios.get(
        `${resources.Merchant_BaseUrl}merchant/getAllMercahnts`
      );
      toast.update(loadingToastId, {
        render: "Details Fetched successfully!",
        type: "success",
        isLoading: false,
        autoClose: 3000, // Auto close after 3 seconds
      });
      return response.data?.merchantDetails || [];
    } catch (err) {
      toast.update(loadingToastId, {
        render: err.response?.data || "Failed to fetch merchants",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      return thunkAPI.rejectWithValue(
        err.response?.data || "Failed to fetch merchants"
      );
    }
  }
);

export const fetchSettlementTransactions = createAsyncThunk(
  "merchants/fetchSettlementTransactions",
  async (merchantId, thunkAPI) => {
    const loadingToastId = toast.loading("Fetching Merchant details...");

    try {
      const response = await axios.get(
        `${resources.Merchant_BaseUrl}SettlementTransactions/getTransactionswithId`,
        {
          params: { MerchantId: merchantId },
        }
      );
      toast.update(loadingToastId, {
        render: "Transactions Fetched successfully!",
        type: "success",
        isLoading: false,
        autoClose: 3000, // Auto close after 3 seconds
      });
      return response.data?.transactionsList;
    } catch (err) {
      toast.update(loadingToastId, {
        render: err.response?.data || "Failed to fetch transactions",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      return thunkAPI.rejectWithValue(
        err.response?.data || "Failed to fetch transactions"
      );
    }
  }
);

export const updateMerchantStatus = createAsyncThunk(
  "merchants/updateMerchantStatus",
  async ({ MerchantId, currentStatus }, thunkAPI) => {
    try {
      const newStatus = currentStatus === "Active" ? "DeActive" : "Active";
      const response = await axios.post(
        `${resources.Merchant_BaseUrl}smsotp/updateuserstatus`,
        { MerchantId, status: newStatus }
      );
      if (response.data.status === 1) {
        return { MerchantId, newStatus };
      }
      throw new Error("Failed to update merchant status");
    } catch (err) {
      return thunkAPI.rejectWithValue(
        err.response?.data || "Failed to update status"
      );
    }
  }
);

// Merchant Slice
const merchantSlice = createSlice({
  name: "merchants",
  initialState: {
    allDetails: [],
    settlementTransactions: [],
    loading: false,
    error: null,
  },
  reducers: {
    updateMerchantInStore: (state, action) => {
      const updatedMerchant = action.payload;
      const index = state.allDetails.findIndex(
        (merchant) => merchant.MerchantId === updatedMerchant.MerchantId
      );
      if (index !== -1) {
        state.allDetails[index] = updatedMerchant;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMerchantDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMerchantDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.allDetails = action.payload;
      })
      .addCase(fetchMerchantDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchSettlementTransactions.fulfilled, (state, action) => {
        state.settlementTransactions = action.payload;
      });
  },
});

export const selectTotalBalance = (state) => {
  return state.merchants.settlementTransactions.reduce(
    (total, item) => total + (item.unSettledAmount || 0),
    0
  );
};

export const getMerchantAllDetails = (state) => {
  // console.log("Current state:", state);
  const merchantAllDetails = state.merchants.allDetails.map((item) => {
    const merchantId = item?.MerchantId?.trim();

    // Find unsettled amount based on merchantId
    const unsettledAmount =
      state.merchants.settlementTransactions.find(
        (transaction) => transaction.MerchantId === merchantId
      )?.unSettledAmount || 0;

    return {
      ...item,
      unSettledAmount: unsettledAmount,
    };
  });

  return merchantAllDetails;
};

export const { updateMerchantInStore } = merchantSlice.actions;

export default merchantSlice.reducer;
