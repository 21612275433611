import React from "react";
import ReactDOM from "react-dom/client";
import "react-bootstrap-submenu/dist/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "react-redux";
import store from "./app/store";
import GlobalContextProvider from "./Components/GlobalContext/GlobalContext";
const queryClient = new QueryClient({
  defaultOptions: {
    stealTime: 120000,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  },
});



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Provider store={store}>
          <GlobalContextProvider>
            <App />
          </GlobalContextProvider>
        </Provider>
      </BrowserRouter>
    </QueryClientProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
