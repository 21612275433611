// Components/NotFound/NotFound.jsx
import React from "react";
import "../../Styles/PageNotFound.css";
const PageNotFound = () => {
  return (
    <div className="notfound-container">
      <h1 className="error-code">404</h1>
      <p className="error-message">
        Oops! The page you're looking for doesn't exist.
      </p>
      <a href="/dashboard" className="home-link">
        Go Back Dashboard
      </a>
    </div>
  );
};

export default PageNotFound;
