import React, { useState, useEffect } from "react";
import "../../Styles/Dashboard2.css";
const CountUp = ({ endValue, duration }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let startValue = 0;
    const increment = endValue / (duration / 10); // Increment the value every 10ms
    const interval = setInterval(() => {
      startValue += increment;
      if (startValue >= endValue) {
        startValue = endValue;
        clearInterval(interval);
      }
      setCount(Math.floor(startValue));
    }, 10);

    return () => clearInterval(interval);
  }, [endValue, duration]);

  return <span>{count}</span>;
};

const ReportCards = () => {
  return (
    <div className="card-conatiner-1">
      <div className="cards">
        <div className="cookieCard card1">
          <p className="cookieHeading">Active Members</p>
          <h2 className="cookieDescription">
            <CountUp endValue={586} duration={2000} />
          </h2>
        </div>
        <div className="cookieCard card2">
          <p className="cookieHeading">Reports</p>
          <h2 className="cookieDescription">
            <CountUp endValue={245} duration={2000} />
          </h2>
        </div>
        <div className="cookieCard card3">
          <p className="cookieHeading">Total Installations</p>
          <h2 className="cookieDescription">
            <CountUp endValue={657} duration={2000} />
          </h2>
        </div>
        <div className="cookieCard card4">
          <p className="cookieHeading">Promembership</p>
          <h2 className="cookieDescription">
            <CountUp endValue={557} duration={2000} />
          </h2>
        </div>
      </div>

      <div className="dashboard-button-container">
        <button className="styled-button button-1">Customer</button>
        <button className="styled-button button-1">Merchant</button>
      </div>
    </div>
  );
};

export default ReportCards;
