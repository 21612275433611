import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { resources } from "../../Components/Resources/Resources";
// Define API Endpoints
const API_ENDPOINTS = {
  BBPS: `${resources.Merchant_BaseUrl}bbpsPaymentsTrasanctions/get/All/getAllBBPSTransactions`,
  Rental: `${resources.Merchant_BaseUrl}transactions/transactions/type/Rental`,
  Educational: `${resources.Merchant_BaseUrl}transactions/transactions/type/educational`,
  Wallet: `${resources.Merchant_BaseUrl}SettlementTransactions/settlements`,
};

// Thunks for API Calls
export const fetchBBPSTransactions = createAsyncThunk(
  "transactions/fetchBBPSTransactions",
  async (_, { rejectWithValue }) => {
    
    try {
      const response = await axios.get(API_ENDPOINTS.BBPS);
      return response.data;
    } catch (err) {
      return rejectWithValue("Error fetching BBPS transactions");
    }
  }
);

export const fetchRentalTransactions = createAsyncThunk(
  "transactions/fetchRentalTransactions",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API_ENDPOINTS.Rental);
      return response.data?.data || [];
    } catch (err) {
      return rejectWithValue("Error fetching Rental transactions");
    }
  }
);

export const fetchEducationalTransactions = createAsyncThunk(
  "transactions/fetchEducationalTransactions",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API_ENDPOINTS.Educational);
      return response.data?.data || [];
    } catch (err) {
      return rejectWithValue("Error fetching Educational transactions");
    }
  }
);

export const fetchWalletTransactions = createAsyncThunk(
  "transactions/fetchWalletTransactions",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API_ENDPOINTS.Wallet);
      return response.data || [];
    } catch (err) {
      return rejectWithValue("Error fetching Wallet transactions");
    }
  }
);

// Slice
const transactionsSlice = createSlice({
  name: "transactions",
  initialState: {
    bbps: [],
    rental: [],
    educational: [],
    wallet: [],
    loading: false, // Common loading state
    error: null, // Common error state
  },

  reducers: {},

  extraReducers: (builder) => {
    builder
      // BBPS Transactions
      .addCase(fetchBBPSTransactions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBBPSTransactions.fulfilled, (state, action) => {
        state.loading = false;
        state.bbps = action.payload;
      })
      .addCase(fetchBBPSTransactions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Rental Transactions
      .addCase(fetchRentalTransactions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRentalTransactions.fulfilled, (state, action) => {
        state.loading = false;
        state.rental = action.payload;
      })
      .addCase(fetchRentalTransactions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Educational Transactions
      .addCase(fetchEducationalTransactions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEducationalTransactions.fulfilled, (state, action) => {
        state.loading = false;
        state.educational = action.payload;
      })
      .addCase(fetchEducationalTransactions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Wallet Transactions
      .addCase(fetchWalletTransactions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchWalletTransactions.fulfilled, (state, action) => {
        state.loading = false;
        state.wallet = action.payload;
      })
      .addCase(fetchWalletTransactions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});


export default transactionsSlice.reducer;
